import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            揖越科技（上海）
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                产品
              </a>
            </li>
            <li>
              <a href="#about" className="page-scroll">
                关于
              </a>
            </li>
            <li>
              <a href="#services" className="page-scroll">
                服务
              </a>
            </li>
            <li>
              <a href="#portfolio" className="page-scroll">
                展示
              </a>
            </li>
            <li>
              <a href="#contact" className="page-scroll">
                联系我们
              </a>
            </li>
            <li>
              <a href="https://wiki.yytech.cc" target="_blank" rel="noopener noreferrer">
                 产品文档
              </a>
            </li>
             <li>
              <a href="https://shop142277418.taobao.com/?spm=2013.1.1000126.d21.707c135dn7CQv5" target="_blank" rel="noopener noreferrer">
                 在线商店
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
